import { useEffect, useReducer, useState } from "react";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { getUserInformationFromCodes } from "../../utils/validator";
import { Techs } from "../../types/user";
import {
  checkIfUserAlreadyExists,
  registerNewUser,
} from "../../services/login";
import QrCodeScanner from "../../components/QrCodeScanner";

const Login = () => {
  const navigate = useNavigate();
  const [showLoginForm, toggleShowLoginForm] = useReducer(
    (state) => !state,
    false
  );
  const [username, setUsername] = useState("");
  const [userCode, setUserCode] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const [tech, setTech] = useState("react-native");

  useEffect(() => {
    if (!showLoginForm) {
      userCode.length >= 4
        ? setIsButtonEnabled(true)
        : setIsButtonEnabled(false);
    }

    if (showLoginForm) {
      username ? setIsButtonEnabled(true) : setIsButtonEnabled(false);
    }
  }, [username, userCode, showLoginForm]);

  const handleOnConfirmPress = async () => {
    if (showLoginForm) {
      fetchLogin();
    } else {
      validateUserCode();
    }
  };

  const handleOnEnterPress = async () => {
    const isLoggedIn = await checkIfUserAlreadyExists(userCode);
    if (isLoggedIn) {
      navigate("/lobby", { replace: true });
    } else {
      setIsButtonEnabled(false);
      toggleShowLoginForm();
    }
  };

  const validateUserCode = () => {
    const isValid = getUserInformationFromCodes(userCode);
    if (!!isValid) {
      handleOnEnterPress();
    } else {
      alert("Código inválido");
    }
  };

  const fetchLogin = async () => {
    try {
      await registerNewUser(username, tech as Techs, userCode);
      navigate("/lobby", { replace: true });
    } catch (err: any) {
      alert(err);
    }
  };

  const handleFoundQrCode = (data: string) => {
    // TODO:
    setUserCode(data);
    setUsername(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.cameraBox}>
        {/* <QrCodeScanner handleQrCode={handleFoundQrCode} /> */}
      </div>
      <div className={styles.loginBox}>
        {!showLoginForm && (
          <input
            type="text"
            value={userCode}
            onChange={(value) => setUserCode(value.target.value)}
            placeholder="Codigo"
          />
        )}

        {showLoginForm && (
          <>
            <input
              type="text"
              value={username}
              onChange={(value) => setUsername(value.target.value)}
              placeholder="Username"
            />
            <label htmlFor="tech">Tech</label>
            <select
              onChange={(val) => setTech(val.target.value)}
              name="tech"
              id="tech"
              className={styles.tech}
            >
              <option value="react-native">React Native</option>
              <option value="react-js">React JS</option>
            </select>
          </>
        )}
        <button
          className={isButtonEnabled ? styles.buttonOn : styles.buttonOff}
          onClick={handleOnConfirmPress}
          disabled={!isButtonEnabled}
        >
          Confirmar
        </button>
      </div>
    </div>
  );
};

export default Login;
