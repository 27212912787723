export const userCodes = [
  {
    code: "1A2B",
    name: "Yukihiro",
  },
  {
    code: "2B3C",
    name: "Ze",
  },
  {
    code: "4C5D",
    name: "Joao",
  },
  {
    code: "1F3F",
    name: "Maria",
  },
];

export const getUserInformationFromCodes = (code: string) => {
  const arrayOfCodes = Object.values(userCodes);

  return arrayOfCodes.find((item) => item.code === code);
};
