import { Firebase } from "../managers/FirebaseManager";
import { Techs, UserType } from "../types/user";
import { getUserInformationFromCodes } from "../utils";

export const registerNewUser = async (
  userName: string,
  tech: Techs,
  code: string
) => {
  try {
    const userInformation = getUserInformationFromCodes(code);

    if (userInformation) {
      const userObject: UserType = {
        id: code,
        name: userInformation.name,
        userName,
        tech,
        teamId: "",
      };

      Firebase.set(`users/${code}`, userObject);

      const user = JSON.stringify({ code, userName });

      localStorage.setItem("user", user);
    }
  } catch (error) {
    throw error;
  }
};

export const checkIfUserAlreadyExists = async (code: string) => {
  const userResponse = await Firebase.get("/users");

  if (!userResponse) return false;

  const listOfUsers: UserType[] = Object.values(userResponse);
  const userExists = !!listOfUsers.find((item) => item.id === code);

  return userExists;
};
